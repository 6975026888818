import styled from "@emotion/styled";
import clsx from "clsx";
import { FC, useRef, useState } from "react";
import React, { createRef, useEffect } from "react";
import translate from "src/lib/translate";
import type { TranslationKey } from "src/types/translationKey";
import PlusIcon from "src/ui/assets/icons/PlusIcon";
import { AppActionButton } from "src/ui/components/AppActionButton/AppActionButton";
import { type ChatError } from "src/ui/components/Chat/ChatBloc";
import Translate from "src/ui/components/Translate/Translate";
import {
  AutoForm,
  AutoFormTextAreaField,
  Button,
  useAutoFormControls
} from "@9amhealth/shared";
import { z } from "zod";
import { StorageController } from "src/state/StorageBloc/StorageBloc";
import { METADATA_CONTENT_VALUE } from "src/constants/misc";

const InputWrap = styled.div`
  label: ChatInputWrap;
  position: fixed;
  background-color: var(--color-gray-lighter);
  padding-bottom: calc(
    var(--ion-safe-area-bottom, 0px) + var(--bottom-nav-height) + 0.5em
  );
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.38, 0.7, 0.125, 1);
  left: 0;
  right: 0;
  z-index: 1000;
  bottom: calc(var(--from-bottom, 0px));

  @media screen and (min-width: 768px) {
    padding-top: calc(0.5em);
    padding-bottom: calc(var(--ion-safe-area-bottom, 0px) + 1em);
    bottom: var(--from-bottom, 0px);
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }

  &[data-focused="true"] {
    --from-bottom: calc(
      var(--stored-keyboard-height, var(--navbar-padding-bottom, 0px)) - var(
          --ion-safe-area-bottom,
          0px
        ) - var(--navbar-padding-bottom, 0px)
    );
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--chat-frame-separator-bg, transparent);
  }
`;

const FormFields = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: min(100%, 640px);
  padding: 0 1em 1em;
  gap: 0.6em;
`;

const ActionButtonWrap = styled.div`
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
  margin-bottom: -0.3em;
`;

const TextSpace = styled.div`
  flex: 1;
  transform: translateY(1em);
  position: relative;
  word-break: break-word;
  textarea {
    border-image: initial;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

const ButtonSpace = styled.div`
  width: 0;
  opacity: 0;
  transition:
    width 0.3s ease-out,
    opacity 0.3s ease-out;
  pointer-events: none;

  &.visible {
    width: 48px;
    opacity: 1;
    pointer-events: auto;
    margin-bottom: -0.3em;
  }
`;

const BlockingError = styled.div`
  position: absolute;
  inset: 1em;
  bottom: calc(100% + 1em);
  top: auto;
  box-shadow: var(--light-shadow);
  padding: 1em 1em;
  background-color: white;
  color: var(--color-error);
  text-align: center;
  border: 2px solid var(--color-error);
  border-radius: var(--border-radius);
  z-index: 100;

  nine-button {
    margin-top: 0.5em;
  }
`;

const InlineError = styled.div`
  position: absolute;
  color: var(--color-error);
  font-size: 0.9em;
  margin-top: 0.5em;
  padding: 0.7em 1.4em;
  bottom: 100%;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--light-shadow);
  border: 1px solid var(--color-error);
  right: 2em;
  max-width: 60%;
  z-index: 1;
  word-break: break-all;
  text-wrap: balance;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const schema = z.object({
  message: z.string().min(1)
});

const ChatInput: FC<{
  onSubmit: (
    message: string,
    file: FileList | null,
    meta?: Record<string, string | string[] | boolean>
  ) => Promise<void>;
  onChange: (wrapHeight: number) => void;
  onFocusChange: (isFocused: boolean) => void;
  errors: ChatError[];
}> = (props) => {
  const wrapRef = createRef<HTMLDivElement>();
  const [showSendButton, setShowSendButton] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [fieldId, setFieldId] = useState("id");
  const [loading, setLoading] = React.useState(false);

  const blurTimeout = useRef<NodeJS.Timeout | null>(null);

  const addFiles = (files: FileList | null) => {
    if (loading) return;
    setLoading(true);
    void props
      .onSubmit("File upload", files, {
        [METADATA_CONTENT_VALUE]: true
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values: { message: string }) => {
    if (loading) return;
    setLoading(true);
    void props
      .onSubmit(values.message, null)
      .then(() => {
        autoFormControls.setValue("message", "");
        autoFormControls.reset();
        setShowSendButton(false);
        StorageController.removeItem("9am.chat.unsent.input");
        setFieldId(new Date().toString());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const autoFormControls = useAutoFormControls({
    onChange: (name: string, value: string) => {
      handleValueChange(value);
    },
    schema,
    initialValue: {
      message: StorageController.getItem("9am.chat.unsent.input") ?? ""
    },
    onSubmit: handleSubmit
  });

  const blockingError = props.errors.find((error) => error.blocking);
  const inlineError = props.errors.filter((error) => !error.blocking);

  const handleValueChange = (v: string) => {
    const value = v.trim();
    StorageController.setItem("9am.chat.unsent.input", value);
    setShowSendButton(value.length > 0);
    const elHeight = wrapRef.current?.getBoundingClientRect().height ?? 0;
    props.onChange(elHeight);
  };

  useEffect(() => {
    const intv = setInterval(() => {
      const wrapHeight = wrapRef.current?.clientHeight ?? 0;
      if (wrapHeight === 0) return;
      props.onChange(wrapHeight);
    }, 200);

    return () => {
      clearInterval(intv);
    };
  }, [wrapRef]);

  useEffect(() => {
    const unsent = StorageController.getItem("9am.chat.unsent.input");
    if (unsent) {
      setShowSendButton(unsent.length > 0);
    }
  }, []);

  const onFocusChange = (isFocused: boolean) => {
    setFocused(isFocused);
    props.onFocusChange(isFocused);
  };

  const onBlurHandle = () => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }

    blurTimeout.current = setTimeout(() => {
      onFocusChange(false);
    }, 350);
  };

  useEffect(() => {
    return () => {
      if (blurTimeout.current) {
        clearTimeout(blurTimeout.current);
      }
    };
  }, []);

  return (
    <InputWrap
      data-focused={focused}
      className={clsx({
        loading: loading
      })}
      ref={wrapRef}
    >
      {blockingError && (
        <BlockingError>
          <Translate msg={blockingError.message} />
          <br />
          <nine-button
            onClick={() => window.location.reload()}
            variant="fill"
            color="black"
            size="sm"
          >
            <Translate msg="retry" />
          </nine-button>
        </BlockingError>
      )}
      <AutoForm {...autoFormControls.props}>
        {inlineError.length > 0 && (
          <InlineError>
            {inlineError.map((error, i) => (
              <div key={i}>
                {error.text
                  ? translate(error.text as TranslationKey)
                  : translate(error.message)}
              </div>
            ))}
          </InlineError>
        )}
        <FormFields>
          <ActionButtonWrap>
            <AppActionButton addFiles={addFiles} />
          </ActionButtonWrap>
          <TextSpace
            onFocus={(): void => onFocusChange(true)}
            onBlur={onBlurHandle}
          >
            <AutoFormTextAreaField
              type="text"
              name="message"
              isDisabled={loading}
              key={fieldId}
            ></AutoFormTextAreaField>
          </TextSpace>
          <ButtonSpace
            className={clsx({
              visible: showSendButton
            })}
          >
            <Button
              onPress={(): void => onFocusChange(false)}
              type="submit"
              theme="sunrise"
              outline
              round
              hideArrow
              isDisabled={loading}
              icon={<PlusIcon />}
            ></Button>
          </ButtonSpace>
        </FormFields>
      </AutoForm>
    </InputWrap>
  );
};

export default ChatInput;
